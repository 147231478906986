import {Injectable} from '@angular/core';

export interface BadgeItem {
    type: string;
    value: string;
}

export interface ChildrenItems {
    state: string;
    target?: boolean;
    name: string;
    type?: string;
    children?: ChildrenItems[];
}

export interface MainMenuItems {
    state: string;
    main_state?: string;
    target?: boolean;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    children?: ChildrenItems[];
}

export interface Menu {
    label: string;
    main: MainMenuItems[];
}

const MENUITEMS = [
    {
        label: 'Usuario',
        main: [
            {
                main_state: 'user',
                state: 'profile',
                name: 'Mi Perfil',
                type: 'link',
                icon: 'ti-user',
                level: 3
            },
        ],
        level: 3
    },
    {
        label: 'Compañia',
        main: [
            {
                state: 'companies',
                name: 'Listar',
                type: 'link',
                icon: 'ti-home',
                level: 1
            },
        ],
        level: 1
    },
    {
        label: 'ADMINISTRATIVO',
        main:
            [
                {
                    main_state: 'admin',
                    state: 'contrato',
                    name: 'Gestión de Contratos',
                    type: 'link',
                    icon: 'ti-pencil-alt',
                    level: 2
                },
                {
                    main_state: 'admin',
                    state: 'centro-costo',
                    name: 'Centro de costos',
                    type: 'link',
                    icon: 'ti-layers',
                    level: 1
                },
                {
                    main_state: 'admin',
                    state: 'jobs',
                    name: 'Cargos',
                    type: 'link',
                    icon: 'ti-receipt',
                    level: 1
                },
            ],
        level: 2
    },
    {
        label: 'USUARIOS',
        main: [
            {
                main_state: 'admin',
                state: 'users',
                name: 'Administración de usuarios',
                type: 'link',
                icon: 'ti-direction-alt',
                level: 1
            }
        ],
        level: 1
    },
    {
        label: 'REPORTES',
        main: [
            {
                main_state: 'admin',
                state: 'reporte-cargos',
                name: 'Cargos',
                type: 'link',
                icon: 'ti-envelope',
                level: 1
            },
            {
                main_state: 'admin',
                state: 'reporte-centro-costo',
                name: 'Centros de costo',
                type: 'link',
                icon: 'ti-layout',
                level: 1
            },
            {
                main_state: 'admin',
                state: 'reporte-contrato',
                name: 'Contratos',
                type: 'link',
                icon: 'ti-pencil-alt',
                level: 1
            },
            {
                main_state: 'admin',
                state: 'reportes',
                name: 'Vacaciones',
                type: 'link',
                icon: 'ti-map-alt',
                level: 1
            },
        ],
        level: 1
    },
    {
        label: 'IMPORTACION',
        staff: true,
        main: [
            {
                main_state: 'admin',
                state: 'importa-data',
                name: 'Importacion masiva datos',
                type: 'link',
                icon: 'ti-upload',
                level: 1,
                staff: true
            },
            {
                main_state: 'admin',
                state: 'importa-certificado',
                name: 'Cargue de documentos',
                type: 'link',
                icon: 'ti-zip',
                level: 1,
                staff: true
            },
        ],
        level: 1
    },
];

@Injectable()
export class MenuItems {
    getAll(): Menu[] {
        return MENUITEMS;
    }

    /*add(menu: Menu) {
      MENUITEMS.push(menu);
    }*/
}
