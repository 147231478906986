import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
//import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { AppRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './view/_layout/auth/auth-layout.component';
import { AdminLayoutComponent } from './view/_layout/admin/admin-layout.component';
import { SharedModule } from './shared/shared.module';
import { BreadcrumbsComponent } from './view/_layout/admin/breadcrumbs/breadcrumbs.component';
import { TitleComponent } from './view/_layout/admin/title/title.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ClientService } from './services/client.service';
import { EmployeeService } from './services/employee.service';
import { NewnessService } from './services/newness.service';
import { OrderService } from './services/order.service';
import { PaymentModeService } from './services/paymentmode.service';
import { NgxSpinnerModule } from "ngx-spinner";
import {FieldsetModule} from 'primeng/fieldset';

@NgModule({
    declarations: [
        AppComponent,
        AuthLayoutComponent,
        AdminLayoutComponent,
        BreadcrumbsComponent,
        TitleComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        RouterModule.forRoot(AppRoutes),
        FormsModule,
        HttpModule,
        HttpClientModule,
        NgxSpinnerModule,
        FieldsetModule


    ],
    exports: [
        FormsModule,
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        ClientService,
        EmployeeService,
        NewnessService,
        OrderService,
        PaymentModeService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
