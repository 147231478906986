import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
//  templateUrl: './app.component.html',
//  styleUrls: ['./app.component.css']
  template: '<router-outlet><spinner></spinner></router-outlet>'
})
export class AppComponent {
  title = 'frontadmin';
}
