import {Injectable} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable'
import {GLOBAL} from './global';
import {dataTreatment} from './common';
@Injectable()
export class OrderService {
    public storage_url: string;
    public new_url: string;
    public identity: any;
    public token: any;

    constructor(private _http: Http) {
        this.new_url = GLOBAL.API_ENDPOINT;
        this.storage_url = GLOBAL.API_STORAGE;
    }
    
    list(order_data: any, token: any) {
        let params = {"json": order_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'order/show', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    create(order_data: any, token: any) {
        let params = {"json": order_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'order/store', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    edit(order_data: any, token: any) {
        let params = {"json": order_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'order/edit', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    getActive(token: any) {
        let params = {"token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'order-type/getActive', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    changeStatus(order_data: any, token: any) {
        let params = {"json": order_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'order/changeStatus', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    toExcel(token: any) {
        let params = {"token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'order/toExcel', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    employees(order_data: any, token: any) {
        let params = {"json": order_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'order/employees', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
}
