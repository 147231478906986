import {Injectable} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable'
import {GLOBAL} from './global';
import {dataTreatment} from './common';
@Injectable()
export class ClientService {
    public storage_url: string;
    public new_url: string;
    public identity: any;
    public token: any;

    constructor(private _http: Http) {
        this.new_url = GLOBAL.API_ENDPOINT;
        this.storage_url = GLOBAL.API_STORAGE;
    }
    
    list(concept_data: any, token: any) {
        let params = {"json": concept_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/show', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    syncUp(concept_data: any, token: any) {
        let params = {"json": concept_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/syncUp', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    changeStatus(concept_data: any, token: any) {
        let params = {"json": concept_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/changeStatus', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    complete(concept_data: any, token: any) {
        let params = {"json": concept_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/complete', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    saveContacts(client_data: any, token: any) {
        let params = {"json": client_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/saveContacts', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    saveProjects(client_data: any, token: any) {
        let params = {"json": client_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/saveProjects', params, {headers: headers})
            .map(res => dataTreatment(res));
    }

    save(client_data: any, token: any) {
        let params = {"json": client_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/store', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    saveRetentions(client_data: any, token: any) {
        let params = {"json": client_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/saveRetentions', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    getById(concept_data: any, token: any) {
        let params = {"json": concept_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/show', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    getProjectsData(concept_data: any, token: any) {
        let params = {"json": concept_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/getProjectsData', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    getItemsByClientId(concept_data: any, token: any) {
        let params = {"json": concept_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/getProjects', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    getAllProjects(concept_data: any, token: any) {
        let params = {"json": concept_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/getAllProjects', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    getContacts(client_data: any, token: any) {
        let params = {"json": client_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/contacts', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    getRetentions(client_data: any, token: any) {
        let params = {"json": client_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/retentions', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    toExcel(token: any) {
        let params = {"token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/toExcel', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    active(token: any) {
        let params = {"token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/active', params, {headers: headers})
            .map(res => dataTreatment(res));
    }

    toExcel_prject(token: any) {
        let params = {"token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'client/toExcelProject', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
}
