import {Injectable} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable'
import {GLOBAL} from './global';
import {dataTreatment} from './common';
@Injectable()
export class EmployeeService {
    public storage_url: string;
    public new_url: string;
    public identity: any;
    public token: any;

    constructor(private _http: Http) {
        this.new_url = GLOBAL.API_ENDPOINT;
        this.storage_url = GLOBAL.API_STORAGE;
    }
    
    listByCompany(concept_data: any, token: any) {
        let params = {"json": concept_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'employee/getByCompany', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    list(concept_data: any, token: any) {
        let params = {"json": concept_data, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'employee/list', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
}
