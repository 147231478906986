import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, AfterViewInit } from '@angular/core';
import 'rxjs/add/operator/filter';
import { state, style, transition, animate, trigger, AUTO_STYLE } from '@angular/animations';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { MenuItems } from '../../../shared/menu-items/menu-items';
import { UserService } from '../../../services/user.service';

export interface Options {
    heading?: string;
    removeFooter?: boolean;
    mapHeader?: boolean;
}

@Component({
    selector: 'app-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.css'],
    providers: [UserService],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('mobileMenuTop', [
            state('no-block, void',
                style({
                    overflow: 'hidden',
                    height: '0px',
                })
            ),
            state('yes-block',
                style({
                    height: AUTO_STYLE,
                })
            ),
            transition('no-block <=> yes-block', [
                animate('400ms ease-in-out')
            ])
        ])
    ]
})

export class AdminLayoutComponent implements OnInit {
    deviceType = 'desktop';
    verticalNavType = 'expanded';
    verticalEffect = 'shrink';
    innerHeight: string;
    isCollapsedMobile = 'no-block';
    isCollapsedSideBar = 'no-block';
    toggleOn = true;
    windowWidth: number;

    public htmlButton: string;
    public identity = null;
    public loading: boolean;
    public status: string;
    public msg: boolean;
    public menu: any;
    public current_url: any;
    public menuObject: any;
    public openedSubsystem: any;

    constructor(public menuItems: MenuItems, public _userService: UserService, private _router: Router) {
        const scrollHeight = window.screen.height - 150;
        this.innerHeight = scrollHeight + 'px';
        this.windowWidth = window.innerWidth;
        this.setMenuAttributs(this.windowWidth);

        this.htmlButton = '<div class="fixed-button">';
        this.htmlButton += '<a href="https://codedthemes.com/item/mash-able-pro-admin-template/" class="btn btn-primary" aria-hidden="true">';
        this.htmlButton += 'Upgrade To Pro</a>';
        this.htmlButton += '</div>';
        this.identity = this._userService.getIdentity();
        console.log(this.identity);
        this.menu = [];
        this.menuObject = {};
        this.openedSubsystem = null;
        if (this.identity != null) {
            this.getMenu();
        } else {
            this.logOut();
        }
    }

    ngOnInit() {
        this.current_url = this._router.url.substring(1);
        //        alert(this.current_url);
    }

    onClickedOutside(e: Event) {
        if (this.windowWidth < 768 && this.toggleOn && this.verticalNavType !== 'offcanvas') {
            this.toggleOn = true;
            this.verticalNavType = 'offcanvas';
        }
    }

    onResize(event) {
        this.innerHeight = event.target.innerHeight + 'px';
        /* menu responsive */
        this.windowWidth = event.target.innerWidth;
        let reSizeFlag = true;
        if (this.deviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 1024) {
            reSizeFlag = false;
        } else if (this.deviceType === 'mobile' && this.windowWidth < 768) {
            reSizeFlag = false;
        }

        if (reSizeFlag) {
            this.setMenuAttributs(this.windowWidth);
        }
    }

    setMenuAttributs(windowWidth) {
        if (windowWidth >= 768 && windowWidth <= 1024) {
            this.deviceType = 'tablet';
            this.verticalNavType = 'collapsed';
            this.verticalEffect = 'push';
        } else if (windowWidth < 768) {
            this.deviceType = 'mobile';
            this.verticalNavType = 'offcanvas';
            this.verticalEffect = 'overlay';
        } else {
            this.deviceType = 'desktop';
            this.verticalNavType = 'expanded';
            this.verticalEffect = 'shrink';
        }
    }

    toggleOpened() {
        if (this.windowWidth < 768) {
            this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
            this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
        } else {
            this.verticalNavType = this.verticalNavType === 'expanded' ? 'collapsed' : 'expanded';
        }
    }

    toggleOpenedSidebar() {
        this.isCollapsedSideBar = this.isCollapsedSideBar === 'yes-block' ? 'no-block' : 'yes-block';
    }

    onMobileMenu() {
        this.isCollapsedMobile = this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
    }

    logOut() {
        this._userService.unsetIdentity();
        this._router.navigate(['/authentication/login']);
    }

    getMenu() {
        this.loading = true;
        this.status = "";
        this._userService.menu({ "profile": this.identity.profile_id }, this._userService.getToken()).subscribe(
            response => {
                this.loading = false;
                this.identity = response;
                this.status = response.status;
                this.msg = response.msg;
                if (this.status == "success") {
                    this.menu = response.data;
                    this.fillMenuObject();
                }
            },
            error => {
                console.log(<any>error);
                this.loading = false;
            }
        );
    }

    fillMenuObject() {
        for (let i = 0; i < this.menu.subsystems.length; i++) {
            for (let k = 0; k < this.menu.subsystems[i].module.length; k++) {

                if (this.menu.subsystems[i].module[k].submodule.length == 0) {
                    let key = this.menu.subsystems[i].module[k].url;
                    this.menuObject[key] = this.menu.subsystems[i].id;
                } else {
                    for (let w = 0; w < this.menu.subsystems[i].module[k].submodule.length; w++) {
                        this.menuObject[this.menu.subsystems[i].module[k].submodule[w].url] = this.menu.subsystems[i].id;
                    }
                }
            }
        }

        this.openedSubsystem = this.menuObject[this.current_url];
        console.log("this.openedSubsystem", this.openedSubsystem);
    }
}
