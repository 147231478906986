<div class="container-scroller">
    <!-- partial:../../partials/_navbar.html -->
    <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
            <a class="navbar-brand brand-logo" href="../../index.html">
                <img src="assets/images/logo_alfa.png" alt="logo" /> </a>
            <a class="navbar-brand brand-logo-mini" href="../../index.html">
                <img src="assets/images/logo_alfa.png" alt="logo" /> </a>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center">
            <ul class="navbar-nav">
                <li class="nav-item font-weight-semibold d-none d-lg-block">
                    <!--Help : +050 2992 709-->
                </li>
                <li class="nav-item dropdown language-dropdown">
                    <!--                    <a class="nav-link dropdown-toggle px-2 d-flex align-items-center" id="LanguageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                                            <div class="d-inline-flex mr-0 mr-md-3">
                                                <div class="flag-icon-holder">
                                                    <i class="flag-icon flag-icon-us"></i>
                                                </div>
                                            </div>
                                            <span class="profile-text font-weight-medium d-none d-md-block">English</span>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-left navbar-dropdown py-2" aria-labelledby="LanguageDropdown">
                                            <a class="dropdown-item">
                                                <div class="flag-icon-holder">
                                                    <i class="flag-icon flag-icon-us"></i>
                                                </div>English
                                            </a>
                                            <a class="dropdown-item">
                                                <div class="flag-icon-holder">
                                                    <i class="flag-icon flag-icon-fr"></i>
                                                </div>French
                                            </a>
                                            <a class="dropdown-item">
                                                <div class="flag-icon-holder">
                                                    <i class="flag-icon flag-icon-ae"></i>
                                                </div>Arabic
                                            </a>
                                            <a class="dropdown-item">
                                                <div class="flag-icon-holder">
                                                    <i class="flag-icon flag-icon-ru"></i>
                                                </div>Russian
                                            </a>
                                        </div>-->
                </li>
            </ul>
            <form class="ml-auto search-form d-none d-md-block" action="#">
                <div class="form-group">
                    <input type="search" class="form-control" placeholder="Search Here">
                </div>
            </form>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item dropdown">
                    <!--                    <a class="nav-link count-indicator" id="messageDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                                            <i class="mdi mdi-bell-outline"></i>
                                            <span class="count">7</span>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="messageDropdown">
                                            <a class="dropdown-item py-3">
                                                <p class="mb-0 font-weight-medium float-left">You have 7 unread mails </p>
                                                <span class="badge badge-pill badge-primary float-right">View all</span>
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item preview-item">
                                                <div class="preview-thumbnail">
                                                    <img src="assets/images/faces/profile/blank-user.jpg" alt="image" class="img-sm profile-pic"> </div>
                                                <div class="preview-item-content flex-grow py-2">
                                                    <p class="preview-subject ellipsis font-weight-medium text-dark">Marian Garner </p>
                                                    <p class="font-weight-light small-text"> The meeting is cancelled </p>
                                                </div>
                                            </a>
                                            <a class="dropdown-item preview-item">
                                                <div class="preview-thumbnail">
                                                    <img src="assets/images/faces/profile/blank-user.jpg" alt="image" class="img-sm profile-pic"> </div>
                                                <div class="preview-item-content flex-grow py-2">
                                                    <p class="preview-subject ellipsis font-weight-medium text-dark">David Grey </p>
                                                    <p class="font-weight-light small-text"> The meeting is cancelled </p>
                                                </div>
                                            </a>
                                            <a class="dropdown-item preview-item">
                                                <div class="preview-thumbnail">
                                                    <img src="assets/images/faces/profile/blank-user.jpg" alt="image" class="img-sm profile-pic"> </div>
                                                <div class="preview-item-content flex-grow py-2">
                                                    <p class="preview-subject ellipsis font-weight-medium text-dark">Travis Jenkins </p>
                                                    <p class="font-weight-light small-text"> The meeting is cancelled </p>
                                                </div>
                                            </a>
                                        </div>-->
                </li>
                <li class="nav-item dropdown">
                    <!--                    <a class="nav-link count-indicator" id="notificationDropdown" href="#" data-toggle="dropdown">
                                            <i class="mdi mdi-email-outline"></i>
                                            <span class="count bg-success">3</span>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0" aria-labelledby="notificationDropdown">
                                            <a class="dropdown-item py-3 border-bottom">
                                                <p class="mb-0 font-weight-medium float-left">You have 4 new notifications </p>
                                                <span class="badge badge-pill badge-primary float-right">View all</span>
                                            </a>
                                            <a class="dropdown-item preview-item py-3">
                                                <div class="preview-thumbnail">
                                                    <i class="mdi mdi-alert m-auto text-primary"></i>
                                                </div>
                                                <div class="preview-item-content">
                                                    <h6 class="preview-subject font-weight-normal text-dark mb-1">Application Error</h6>
                                                    <p class="font-weight-light small-text mb-0"> Just now </p>
                                                </div>
                                            </a>
                                            <a class="dropdown-item preview-item py-3">
                                                <div class="preview-thumbnail">
                                                    <i class="mdi mdi-settings m-auto text-primary"></i>
                                                </div>
                                                <div class="preview-item-content">
                                                    <h6 class="preview-subject font-weight-normal text-dark mb-1">Settings</h6>
                                                    <p class="font-weight-light small-text mb-0"> Private message </p>
                                                </div>
                                            </a>
                                            <a class="dropdown-item preview-item py-3">
                                                <div class="preview-thumbnail">
                                                    <i class="mdi mdi-airballoon m-auto text-primary"></i>
                                                </div>
                                                <div class="preview-item-content">
                                                    <h6 class="preview-subject font-weight-normal text-dark mb-1">New user registration</h6>
                                                    <p class="font-weight-light small-text mb-0"> 2 days ago </p>
                                                </div>
                                            </a>
                                        </div>-->
                </li>
                <li class="nav-item dropdown d-none d-xl-inline-block user-dropdown">
                    <a class="nav-link dropdown-toggle" id="UserDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                        <img class="img-xs rounded-circle" src="assets/images/faces/profile/blank-user.jpg" alt="Profile image"> </a>
                    <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                        <div class="dropdown-header text-center" *ngIf="_userService.identity!=null">
                            <img class="img-md rounded-circle" src="assets/images/faces/profile/blank-user.jpg" alt="Profile image">
                            <p class="mb-1 mt-3 font-weight-semibold">{{_userService.identity.first_name}} {{_userService.identity.first_lastname}}</p>
                            <p class="font-weight-light text-muted mb-0">{{_userService.identity.email}}</p>
                        </div>
                        <a class="dropdown-item" (click)="logOut()">Cerrar sesi&oacute;n<i class="dropdown-item-icon ti-power-off"></i></a>
                    </div>
                </li>
            </ul>
            <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                <span class="mdi mdi-menu"></span>
            </button>
        </div>
    </nav>
    <!-- partial -->
    <div class="container-fluid page-body-wrapper-menu">
        <!-- partial:../../partials/_sidebar.html -->
        <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav nav-main-menu">
                <li class="nav-item nav-profile">
                    <a href="#" class="nav-link">
                        <div class="profile-image">
                            <img class="img-xs rounded-circle" src="assets/images/faces/profile/blank-user.jpg" alt="profile image">
                            <div class="dot-indicator bg-success"></div>
                        </div>
                        <div class="text-wrapper" *ngIf="_userService.identity!=null">
                            <p class="profile-name">{{_userService.identity.first_name}} {{_userService.identity.first_lastname}}</p>
                            <p class="designation">{{_userService.identity.profile}}</p>
                        </div>
                    </a>
                </li>
                <li class="nav-item nav-category">Men&uacute; {{openedSubsystem}}</li>

                <!--{{menu|json}}-->
                <ng-container *ngIf="menu.hasOwnProperty('subsystems')">
                    <li class="nav-item nav-item-main-menu" *ngFor="let subsystem of menu.subsystems">
                        <a class="nav-link" data-toggle="collapse" href="#ui-subsytem-{{subsystem.id}}" aria-expanded="false" attr.aria-controls="ui-subsytem-{{subsystem.id}}" >
                            <i class="menu-icon typcn typcn-coffee"></i>
                            <span class="menu-title">{{subsystem.description}}</span>
                            <i class="menu-arrow"></i>
                        </a>
                        <div class="collapse" [ngClass]="subsystem.id==openedSubsystem?'show':''" id="ui-subsytem-{{subsystem.id}}">
                            <ul class="nav flex-column sub-menu">
                                <li class="nav-item" *ngFor="let module of subsystem.module">
                                    <ng-container *ngIf="module.submodule.length>0">
                                        <a class="nav-link" data-toggle="collapse" href="#ui-submodule-{{module.id}}" aria-expanded="false" attr.aria-controls="ui-submodule-{{module.id}}">
                                            {{module.description}}
                                        </a>
                                        <div class="collapse" id="ui-submodule-{{module.id}}">
                                            <ul class="nav flex-column sub-menu">
                                                <li class="nav-item" *ngFor="let submodule of module.submodule">
                                                    <a class="nav-link" [routerLink]="['/', submodule.url]">
                                                        {{submodule.description}}
                                                    </a>
                                                </li>
                                            </ul> 
                                        </div>

                                    </ng-container>

                                    <ng-container *ngIf="module.submodule.length==0">
                                        <a class="nav-link" [routerLink]="['/', module.url]">
                                            {{module.description}}
                                        </a>
                                    </ng-container>
                                </li>
                        </ul>
                    </div>
                </li>
            </ng-container>
        </ul>
    </nav>
    <!-- partial -->
    <div class="main-panel">
        <div class="content-wrapper-menu">
            <app-title></app-title>
            <!--<app-breadcrumbs></app-breadcrumbs>-->
            <div class="page-body">
                <router-outlet>
                    <spinner></spinner>
                </router-outlet>
            </div>
        </div>
    </div>
    <!-- main-panel ends -->
</div>
<!-- page-body-wrapper ends -->
</div>
