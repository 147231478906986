import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable'
import { GLOBAL } from './global';
import { dataTreatment } from './common';
@Injectable()
export class NewnessService {
    public storage_url: string;
    public new_url: string;
    public identity: any;
    public token: any;

    constructor(private _http: Http) {
        this.new_url = GLOBAL.API_ENDPOINT;
        this.storage_url = GLOBAL.API_STORAGE;
    }

    list(concept_data: any, token: any) {
        let params = { "json": concept_data, "token": token };
        let headers = new Headers({ 'content-type': 'application/json' });

        return this._http.post(this.new_url + 'newness/index', params, { headers: headers })
            .map(res => dataTreatment(res));
    }

    list2(concept_data: any, token: any) {
        let params = { "json": concept_data, "token": token };
        let headers = new Headers({ 'content-type': 'application/json' });

        return this._http.post(this.new_url + 'newness/show', params, { headers: headers })
            .map(res => dataTreatment(res));
    }

    create(concept_data: any, token: any) {
        let params = { "json": concept_data, "token": token };
        let headers = new Headers({ 'content-type': 'application/json' });

        return this._http.post(this.new_url + 'newness/store', params, { headers: headers })
            .map(res => dataTreatment(res));
    }

    edit(concept_data: any, token: any) {
        let params = { "json": concept_data, "token": token };
        let headers = new Headers({ 'content-type': 'application/json' });

        return this._http.post(this.new_url + 'newness/edit', params, { headers: headers })
            .map(res => dataTreatment(res));
    }

    getActive(token: any) {
        let params = { "token": token };
        let headers = new Headers({ 'content-type': 'application/json' });

        return this._http.post(this.new_url + 'newness-type/getActive', params, { headers: headers })
            .map(res => dataTreatment(res));
    }

    deleteNewness(concept_data: any, token: any) {
        let params = { "json": concept_data, "token": token };
        let headers = new Headers({ 'content-type': 'application/json' });

        return this._http.post(this.new_url + 'newness/delete', params, { headers: headers })
            .map(res => dataTreatment(res));
    }
}
