import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';

import { MenuItems } from './menu-items/menu-items';
import { FormsModule } from '@angular/forms';
import { SpinnerComponent } from './spinner/spinner.component';

//import { AlertComponent } from '../alert/index';

import { CardComponent } from './card/card.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastyModule } from 'ng2-toasty';
//import { ScrollModule } from '../scroll/scroll.module';


import {CardRefreshDirective} from './card/card-refresh.directive';
import {CardToggleDirective} from './card/card-toggle.directive';



@NgModule({
  imports: [
      CommonModule,
      FormsModule,
      NgbModule,
      ToastyModule.forRoot(),
      //ScrollModule,
  ],
  declarations: [
    SpinnerComponent,
    //AlertComponent,
    CardComponent,
    CardRefreshDirective,
    CardToggleDirective,
  ],
  exports: [
    SpinnerComponent,
    //AlertComponent,
    CardComponent,
    FormsModule,
    NgbModule,
    CardRefreshDirective,
    CardToggleDirective,
    ToastyModule,
    //ScrollModule,
  ],
  providers: [
      MenuItems,
  ]
})
export class SharedModule { }
