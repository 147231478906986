/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./title.component";
import * as i2 from "@angular/router";
import * as i3 from "@angular/platform-browser";
var styles_TitleComponent = [];
var RenderType_TitleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TitleComponent, data: {} });
export { RenderType_TitleComponent as RenderType_TitleComponent };
export function View_TitleComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
export function View_TitleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-title", [], null, null, null, View_TitleComponent_0, RenderType_TitleComponent)), i0.ɵdid(1, 49152, null, 0, i1.TitleComponent, [i2.Router, i2.ActivatedRoute, i3.Title], null, null)], null, null); }
var TitleComponentNgFactory = i0.ɵccf("app-title", i1.TitleComponent, View_TitleComponent_Host_0, {}, {}, []);
export { TitleComponentNgFactory as TitleComponentNgFactory };
