import {Injectable} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import {Observable} from 'rxjs/Observable'
import {GLOBAL} from './global';
import * as jwt_decode from "jwt-decode";
import {dataTreatment} from './common';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
@Injectable()
export class UserService {
    public url: string;
    public new_url: string;
    public identity: any = null;
    public token: any;
    public storage_url: string;

    constructor(private _http: Http) {
        this.new_url = GLOBAL.API_ENDPOINT;
        this.getIdentity();
        this.storage_url = GLOBAL.API_STORAGE;
    }

    getIdentity() {
        let identity = localStorage.getItem('token');
        this.identity = identity;
        if (identity != null) {
            this.identity = jwt_decode(identity);
        } else {
            this.identity = null;
        }
        
        this.getToken();
        
        return this.identity;
    }
    
    getToken() {
        let token = localStorage.getItem('token');
        this.token = token;
        return this.token;
    }

    signup(user_to_login) {
        let params = {"json": user_to_login};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'login', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    rememberUser(user_data: any) {
        let params = {"json": user_data};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'authentication/forgotUser', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    setPasswordChangeRequest(user_data: any) {
        let params = {"json": user_data};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'authentication/forgotPassword', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    getUserRequestData(user_data: any) {
        let params = {"json": user_data};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'authentication/getUserRequestData', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    changePasswordByRequest(user_data: any) {
        let params = {"json": user_data};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'authentication/changePasswordByRequest', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    setIdentity(identity){
        localStorage.setItem('token', identity);
        this.identity = identity;
    }
    
    unsetIdentity(){
        localStorage.removeItem('token');
        this.identity = null;
    }
    
    menu(profile: any, token: any) {
        let params = {"json": profile, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'profile/permissions', params, {headers: headers})
            .map(res => res.json());
    }
    
    permissionsByModule(profile: any, token: any) {
        let params = {"json": profile, "token": token};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'profile/permissionsByModule', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
    
    validatePermission(profile: any, token: any) {
        let params = {"json": profile, "token": token, "hasOptionId": true};
        let headers = new Headers({'content-type': 'application/json'});

        return this._http.post(this.new_url + 'profile/validatePermission', params, {headers: headers})
            .map(res => dataTreatment(res));
    }
}
