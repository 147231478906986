import { Injectable } from '@angular/core';
import { SpinnerComponent } from '../shared/spinner/spinner.component';

@Injectable()
export class SpinnerService {
  private spinnerCache = new Set<SpinnerComponent>();
  _register(spinner: SpinnerComponent): void {
    this.spinnerCache.add(spinner);
  }

  show(spinnerName: string): void {
    console.log(this.spinnerCache);
    this.spinnerCache.forEach(spinner => {
       console.log(spinner);
      //if (spinner.name === spinnerName) {
        spinner.isSpinnerVisible = true;
      //}
    });
  }

}
