export function dataTreatment(res: any) {
    let val = res.json();
    if (val.hasOwnProperty('token')) {
        if (val.token != null) {
            setIdentity(val.token);
        }
    }
    return val;
}

function setIdentity(identity) {
    localStorage.setItem('token', identity);
}

